import React, {useEffect, useRef, useState} from 'react';
import Map from 'react-map-gl';
import {Box, Checkbox, Select, Stack, useColorModeValue, useDisclosure} from "@chakra-ui/react";
import DeckGL from '@deck.gl/react';
import {GeoJsonLayer} from '@deck.gl/layers';
import {rgbToHex} from "../../utils/helpers";
// import theGeojson from "./gadm41_IRN";
import afCountryBordersGeoJson from "./afghanistan-country-borders";
import afDivisionBordersGeoJson from "./afghanistan-divisions";
import azCountryBordersGeoJson from "./azerbaijan-country-borders";
import azDivisionBordersGeoJson from "./azerbaijan-divisions";
import eaCountryBordersGeoJson from "./eurasia-border";
import eaDivisionBordersGeoJson from "./eurasia-divisions";
import lbCountryBordersGeoJson from "./lebanon-country-borders";
import ruCountryBordersGeoJson from "./russia-country-borders";
import lbDivisionBordersGeoJson from "./lebanon-divisions";
import ruDivisionBordersGeoJson from "./russia-divisions";
import syCountryBordersGeoJson from "./syria-country-borders";
import syDivisionBordersGeoJson from "./syria-divisions";
import trCountryBordersGeoJson from "./turkiye-country-borders";
import trDivisionBordersGeoJson from "./turkiye-divisions";
import iqCountryBordersGeoJson from "./iraq-country-borders";
import iqDivisionBordersGeoJson from "./iraq-divisions";
// import ReactJQCloud from 'react-jqcloud'
import {configs} from "../../Configs";
import {TerrainLayer} from '@deck.gl/geo-layers';
import './corner-indicator.css';
import googleIcon from './media/google.png';
import instagramIcon from './media/instagram.png';
import trends24Icon from './media/trends24.png';
import twitterIcon from './media/twitter.png';
import facebookIcon from './media/facebook.png';
import youtubeIcon from './media/youtube.svg';
import webIcon from './media/web.svg';
import telegramIcon from './media/telegram.svg';
import tiktokIcon from './media/tiktok.svg';
import WordCloud from './WordCloud';
import SourceAlert from './SourceAlert';
import './tags-style.css';
import ReactSelect from 'react-select';
import useAxios from "../../hooks/useAxois";
import {FaOdnoklassniki, FaVk} from "react-icons/fa";
import {FormattedMessage, useIntl} from 'react-intl';

const countryBordersGeoJson = {
    af: afCountryBordersGeoJson,
    az: azCountryBordersGeoJson,
    ea: eaCountryBordersGeoJson,
    lb: lbCountryBordersGeoJson,
    ru: ruCountryBordersGeoJson,
    sy: syCountryBordersGeoJson,
    tr: trCountryBordersGeoJson,
    iq: iqCountryBordersGeoJson
}
const divisionBordersGeoJson = {
    af: afDivisionBordersGeoJson,
    az: azDivisionBordersGeoJson,
    ea: eaDivisionBordersGeoJson,
    lb: lbDivisionBordersGeoJson,
    ru: ruDivisionBordersGeoJson,
    sy: syDivisionBordersGeoJson,
    tr: trDivisionBordersGeoJson,
    iq: iqDivisionBordersGeoJson
}

export default function TextScatterplotMap({
                                               shouldRefresh,
                                               refreshInterval,
                                               cluster = false,
                                               radius = 10
                                           }) {
    const intl = useIntl();
    const cstmMutliSelectMenu = {
        background: useColorModeValue("var(--chakra-colors-gray-300)", "var(--chakra-colors-gray-600)"),
        color: "var(--chakra-colors-gray-800)",
        borderRadius: 'var(--chakra-radii-sm)'
    }

    const cstmMutliSelectOptions = {
        background: useColorModeValue("var(--chakra-colors-gray-300)", "var(--chakra-colors-gray-600)"),

        "&:hover": {
            background: useColorModeValue('var(--chakra-colors-gray-200)', 'var(--chakra-colors-whiteAlpha-100)'),
        },
        "&:focus-visible": {
            background: 'var(--chakra-colors-transparent)',
            borderColor: '#63b3ed'
        }
    }

    const cstmMutliSelect = {
        width: '100%',
        minWidth: '0px',
        outline: '2px solid transparent',
        outlineOffset: '2px',
        position: 'relative',
        appearance: 'none',
        // transitionProperty: 'var(--chakra-transition-property-common)',
        // transitionDuration: 'var(--chakra-transition-duration-normal)',
        fontSize: 'var(--chakra-fontSizes-sm)',
        color: "var(--chakra-colors-gray-800)",
        minHeight: 'var(--chakra-sizes-9)',
        borderRadius: 'var(--chakra-radii-sm)',
        border: '1px solid',
        borderColor: 'var(--chakra-colors-transparent)',
        background: "white",
        "&:focus-visible": {
            borderColor: '#63b3ed'
        }
    }
    const axiosInstance = useAxios();
    const {isOpen, onOpen, onClose} = useDisclosure()
    const cancelRef = useRef()

    const [isMounted, setIsMounted] = useState(false);
    const [isLoading, setIsLoading] = useState(0);
    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);
    const [zoom, setZoom] = useState(0);
    const [data, setData] = useState([]);
    const [targetSource, setTargetSource] = useState({});
    const [province, setProvince] = useState('all');
    const [timerange, setTimerange] = useState(configs.raqib1.mapsFilters.timeranges[1].value);
    const [sourceList, setSourceList] = useState(Object.values(configs.raqib1.mapsSourcesVals));
    const [viewState, setViewState] = useState(configs.raqib1.mapsOsmProvider.deckGl.initial_states);
    const [forceRefresh, setForceRefresh] = useState(false);
    const [selFactions, setSelFactions] = useState([]);
    const [factions, setFactions] = useState([]);

    // disabling zoom with ctrl/command + +/-/mouse wheel
    useEffect(() => {
        const handleKeyPress = (event) => {
            if ((event.ctrlKey || event.metaKey) && (event.key === '=' || event.key === '-')) {
                event.preventDefault();
            }
        };

        const handleMouseWheel = (event) => {
            if (event.ctrlKey || event.metaKey) {
                event.preventDefault();
            }
        };

        document.addEventListener('keydown', handleKeyPress);
        document.addEventListener('wheel', handleMouseWheel, {passive: false});

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
            document.removeEventListener('wheel', handleMouseWheel, {passive: false});
        };
    }, []);

    const generateRandomId = () => {
        return Math.random().toString(16).slice(2);
    }

    const mapRef = useRef();

    const findProvinceGeoJson = (bordersGeoJson) => {
        if (province === 'all') return;
        return bordersGeoJson.features.find(loc => loc.properties?.name.toLowerCase() === province)
    }

    const fetchResources = () => {
        if (isLoading > 0) return;

        let payload = {
            state: province, source: '', timerange: `PT${timerange}S`
        }

        setData([]);

        if (sourceList.length === 0) return;
        let apiCalls = [];

        sourceList.map(source => {
            if (!source.length) return;
            setIsLoading(v => v + 1);
            payload.source = source;
            const tag_ids = selFactions.map(fcn => `tag_ids=${fcn.value}`).join('&')

            apiCalls.push(
                axiosInstance.get(tag_ids ? `/raqib1?${tag_ids}` : "/raqib1/", {
                    headers: {'Client-Timezone': configs.time_locale},
                    params: payload
                }).catch((error) => console.error(error)).finally(() => {
                    setIsLoading(v => v - 1);
                })
            );

        });

        Promise.all(apiCalls).then(responses => {
            const items = [].concat(...responses.map(res => res?.data).filter(res => res !== undefined));
            setData((oldData) => {
                return items.filter((item) => sourceList.includes(item.source)).map((item, index) => {
                    const id = generateRandomId();
                    return {
                        word: item.title,
                        weight: item.count,
                        color: rgbToHex(...configs.raqib1.mapsOsmProvider.colors[item.source]),
                        word_type: id,
                        ...item
                    }
                });
            });
        });
    }

    const generateLayer = (onClick) => {
        return [
            // country | province borders
            new GeoJsonLayer({
                id: 'region',
                data: (province === 'all') ? countryBordersGeoJson?.[configs.country_code] : findProvinceGeoJson(divisionBordersGeoJson?.[configs.country_code]),
                pickable: true,
                stroked: true,
                filled: true,
                lineWidthScale: 5,
                lineWidthMinPixels: 5,
                getFillColor: () => [0, 0, 0, 150],
                getLineColor: () => [0, 0, 255],
                getLineWidth: 1
            }), new TerrainLayer({
                id: 'terrain',
                minZoom: 0,
                maxZoom: 18,
                pickable: true, // onClick: (info, event) => console.log('Clicked:', info.coordinate),
                // onClick: (info, event) => {
                //     this.props.newFoci({lon: info.coordinate[0], lat: info.coordinate[1]})
                // },
                strategy: 'no-overlap',
                meshMaxError: 2.0,
                wireframe: false,
                color: [0, 0, 0]
            }),];
    }

    const handleSourceChange = (e) => {
        let prevSourceList = sourceList;
        let sourceItemIndex = prevSourceList.indexOf(e.target.value);
        if (sourceItemIndex !== -1) {
            prevSourceList.splice(sourceItemIndex, 1);
        } else {
            prevSourceList.push(e.target.value);
        }
        setSourceList([...prevSourceList]);
    }

    // change map viewport based on selected province
    useEffect(() => {
        let newCoordinates = configs.raqib1.mapsOsmProvider.deckGl.initial_states;
        if (province !== 'all') {
            const provinceObj = configs.locations.filter(loc => loc.value === province)[0];
            newCoordinates = {
                ...newCoordinates,
                latitude: provinceObj.geo.lat,
                longitude: provinceObj.geo.lon,
                zoom: provinceObj.zoom
            }
        }
        setViewState(newCoordinates);
    }, [province]);

    // first data loading
    useEffect(() => {
        setIsMounted(true);
        fetchResources();

        // fetch factions list
        axiosInstance.get('/raqib2_tag').then((data) => setFactions(data.data.map(item => (
            {
                value: item.tag_id,
                label: item.title
            }
        ))));
    }, []);

    // refresh data loading
    useEffect(() => {
        if (!isMounted) return;
        fetchResources();
    }, [viewState, sourceList, timerange, forceRefresh, selFactions]);

    useEffect(() => {
        if (!isMounted) return;
        onOpen();
    }, [targetSource]);

    useEffect(() => {

        if (!shouldRefresh) {
            return
        }

        const theInterval = setInterval(() => {
            setForceRefresh(prevForceRefresh => !prevForceRefresh);
        }, refreshInterval * 1000);

        return () => {
            clearInterval(theInterval);
        };
    }, []);

    return (
        <>
            <SourceAlert target={targetSource} cancelRef={cancelRef} isOpen={isOpen} onOpen={onOpen} onClose={onClose}/>
            <DeckGL
                controller={configs.raqib1.mapsOsmProvider.deckGl.controller}
                initialViewState={viewState}
                getCursor={() => 'arrow'}
                getTooltip={({layer, object}) => {
                    if (!object || layer.id !== 'tags') return
                    let theSource = configs.raqib1.sources.filter(source => source.value === object.source)
                    theSource = theSource.length ? theSource : configs.raqib1.rss_platforms.filter(source => source.value === object.source);

                    return `${object.title}" (${object.count}) [${intl.formatMessage({
                        id: `raqib1.${theSource[0]?.value}`,
                        defaultMessage: theSource[0]?.label
                    })}]`
                }}
                onHover={(e) => {
                    if (configs.raqib1.mapsOsmProvider.deckGl.showCoordinates && e?.coordinate) {
                        setLng(e.coordinate[0])
                        setLat(e.coordinate[1])
                        setZoom(e.viewport.zoom)
                    }
                }}
                onClick={() => {
                    if (configs.raqib1.mapsOsmProvider.deckGl.showCoordinates) {
                        navigator.clipboard.writeText(`{lat: ${lat}, lon: ${lng}}, zoom: ${zoom}},`)
                    }
                }}
                layers={generateLayer(() => {
                })}
            >
                {configs.raqib1.mapsOsmProvider.deckGl.showCoordinates &&
                    <div style={{position: 'absolute', top: 15, left: 15, zIndex: 999}}>
                        <p>lat:{lat}</p>
                        <p>lng:{lng}</p>
                        <p>zoom:{zoom}</p>
                    </div>}

                <div className={isLoading ? "loading loading-active" : "loading loading-inactive"}>
                    <div className="loading-activity"/>
                </div>

                <Box w={'160px'} p={4} style={{position: 'absolute', top: 15, left: 15, zIndex: 999}}>
                    <Stack spacing={2} direction='column'>
                        <Box w={'160px'}>
                            <Select
                                backgroundColor={'white'}
                                size="sm"
                                value={timerange}
                                onChange={(e) => setTimerange(e.target.value)}>
                                {configs.raqib1.mapsFilters.timeranges.map((timerange) => (
                                    <option key={"timerange:" + timerange.value} value={timerange.value}>
                                        <FormattedMessage
                                            id={`raqib1.timeRange${timerange.value}`}
                                            defaultMessage={timerange.label}
                                        />
                                    </option>
                                ))}
                            </Select>
                        </Box>
                        <Box w={'160px'}>
                            <Select
                                // style={{width:'150px'}}
                                // variant="filled"
                                backgroundColor={'white'}
                                size="sm"
                                value={province}
                                onChange={(e) => setProvince(e.target.value)}>
                                <option key={"province:all"} value={"all"}>
                                    <FormattedMessage
                                        id="raqib1.allStates"
                                        defaultMessage="All States"
                                    />
                                </option>
                                {configs.locations.map((loc) => (
                                    <option key={"province:" + loc.value} value={loc.value}>
                                        <FormattedMessage
                                            id={`raqib1.${loc.value !== 'all' ? loc.value : loc.label}`}
                                            defaultMessage={loc.label}
                                        />
                                    </option>
                                ))}
                            </Select>
                        </Box>
                        <Box w={'160px'}>
                            <ReactSelect
                                value={factions.find(fcn => selFactions.includes(fcn.value))}
                                onChange={(fcns) => setSelFactions(fcns)}
                                placeholder={intl.formatMessage({id: 'raqib1.topics', defaultMessage: 'Topics'})}
                                isMulti
                                options={factions}
                                closeMenuOnSelect={false}
                                classNames={
                                    {
                                        control: (state) => state.isFocused ? 'border-red-600' : 'border-grey-300'
                                    }
                                }
                                styles={{
                                    indicatorsContainer: (baseStyles) => ({...baseStyles, color: '#000'}),
                                    indicatorSeparator: (baseStyles) => ({
                                        ...baseStyles,
                                        backgroundColor: 'var(--chakra-colors-gray-800)'
                                    }),
                                    placeholder: (baseStyles) => ({
                                        ...baseStyles,
                                        color: 'var(--chakra-colors-gray-800)'
                                    }),
                                    control: (baseStyles) => ({...baseStyles, ...cstmMutliSelect}),
                                    menu: (baseStyles) => ({...baseStyles, ...cstmMutliSelectMenu}),
                                    option: (baseStyles) => ({...baseStyles, ...cstmMutliSelectOptions})
                                }}
                            />
                        </Box>
                    </Stack>
                </Box>

                <Box p={4} backgroundColor={'white'}
                     style={{position: 'absolute', bottom: 15, left: 15, zIndex: 999, borderRadius: 5}}>
                    <Stack spacing={5} direction='row'>
                        <Checkbox defaultChecked onChange={handleSourceChange}
                                  colorScheme='green'
                                  value={configs.raqib1.mapsSourcesVals.GOOGLE_TRENDS}>
                            <img src={googleIcon} alt="google" style={{maxWidth: '16px'}}/>
                        </Checkbox>
                        <Checkbox defaultChecked onChange={handleSourceChange}
                                  colorScheme='gray'
                                  value={configs.raqib1.mapsSourcesVals.TRENDS24}>
                            <img src={trends24Icon} alt="trends24" style={{maxWidth: '16px'}}/>
                        </Checkbox>
                        {/*<Checkbox defaultChecked onChange={handleSourceChange}*/}
                        {/*          colorScheme='gray'*/}
                        {/*          value={configs.raqib1.mapsSourcesVals.TRENDSMAP}>*/}
                        {/*    <img src={trendsmapIcon} alt="trendsmap" style={{maxWidth:'16px'}}/>*/}
                        {/*</Checkbox>*/}
                        <Checkbox defaultChecked onChange={handleSourceChange}
                                  colorScheme='gray'
                                  value={configs.raqib1.mapsSourcesVals.TWITTER}>
                            <img src={twitterIcon} alt="twitter" style={{maxWidth: '16px'}}/>
                        </Checkbox>
                        <Checkbox defaultChecked onChange={handleSourceChange}
                                  colorScheme='red'
                                  value={configs.raqib1.mapsSourcesVals.RSS_INSTAGRAM}>
                            <img src={instagramIcon} alt="instagram" style={{maxWidth: '16px'}}/>
                        </Checkbox>
                        <Checkbox defaultChecked onChange={handleSourceChange}
                                  colorScheme='facebook'
                                  value={configs.raqib1.mapsSourcesVals.RSS_FACEBOOK}>
                            <img src={facebookIcon} alt="facebook" style={{maxWidth: '16px'}}/>
                        </Checkbox>
                        <Checkbox defaultChecked onChange={handleSourceChange}
                                  colorScheme='purple'
                                  value={configs.raqib1.mapsSourcesVals.RSS_YOUTUBE}>
                            <img src={youtubeIcon} alt="YouTube" style={{maxWidth: '16px'}}/>
                        </Checkbox>
                        <Checkbox defaultChecked onChange={handleSourceChange}
                                  colorScheme='pink'
                                  value={configs.raqib1.mapsSourcesVals.RSS_TIKTOK}>
                            <img src={tiktokIcon} alt="TikTok" style={{maxWidth: '16px'}}/>
                        </Checkbox>
                        <Checkbox defaultChecked onChange={handleSourceChange}
                                  colorScheme='telegram'
                                  value={configs.raqib1.mapsSourcesVals.RSS_TELEGRAM}>
                            <img src={telegramIcon} alt="Telegram" style={{maxWidth: '16px'}}/>
                        </Checkbox>
                        <Checkbox defaultChecked onChange={handleSourceChange}
                                  colorScheme='yellow'
                                  value={configs.raqib1.mapsSourcesVals.RSS_OTHER}>
                            <img src={webIcon} alt="Other" style={{maxWidth: '16px'}}/>
                        </Checkbox>
                        <Checkbox defaultChecked onChange={handleSourceChange}
                                  colorScheme='teal'
                                  value={configs.raqib1.mapsSourcesVals.RSS_VK}>
                            <FaVk/>
                        </Checkbox>
                        <Checkbox defaultChecked onChange={handleSourceChange}
                                  colorScheme='cyan'
                                  value={configs.raqib1.mapsSourcesVals.RSS_OK}>
                            <FaOdnoklassniki/>
                        </Checkbox>
                    </Stack>

                </Box>

                {data.length > 0 && <WordCloud
                    words={data}
                    verticalEnabled={false} fontOffset={0} minFont={16} maxFont={32} font_family={'Cairo'}
                    word_click={function () {
                        const id = window.$(this)[0].attributes.word_type.value;
                        const tag = data.find(item => item.word_type === id)
                        setTargetSource({...tag});
                    }}
                />}


                <Map
                    ref={mapRef}
                    mapboxAccessToken={configs.raqib1.mapsOsmProvider.token}
                    mapStyle={useColorModeValue(configs.raqib1.mapsOsmProvider.deckGl.lightStyle, configs.raqib1.mapsOsmProvider.deckGl.darkStyle)}
                    terrain={configs.raqib1.mapsOsmProvider.deckGl.terrain}
                    scrollZoom={configs.raqib1.mapsOsmProvider.deckGl.scrollZoom}
                    touchZoom={configs.raqib1.mapsOsmProvider.deckGl.touchZoom}
                    keyboard={configs.raqib1.mapsOsmProvider.deckGl.keyboard}
                    doubleClickZoom={configs.raqib1.mapsOsmProvider.deckGl.doubleClickZoom}
                    attributionControl={configs.raqib1.mapsOsmProvider.attribution.show}
                    customAttribution={configs.raqib1.mapsOsmProvider.attribution.text}
                />
            </DeckGL>
        </>
    );
}