import {
    Box,
    ButtonGroup,
    Card,
    CardBody,
    Divider,
    Flex,
    Heading,
    Image,
    Link,
    Spacer,
    Stack,
    Text,
    Tooltip,
    useToast
} from "@chakra-ui/react";
import {FaGoogle, FaPencilAlt, FaRegStar, FaStar} from 'react-icons/fa';
import {useIntl} from "react-intl";
import {useAuth} from "../../hooks/useAuth";
import {ArrowForwardIcon, ExternalLinkIcon} from "@chakra-ui/icons"
import {configs} from "../../Configs";
import {useEffect, useRef, useState} from "react";
import {dtToLocaleString, gregorianJalaliDTConversion, setTextDirection} from '../../utils/helpers';
import HTMLParser from "../../utils/HtmlParser";
import useAxios from "../../hooks/useAxois";


export const Post = ({
                         image = null,
                         title,
                         description,
                         published_at,
                         source,
                         link,
                         refr,
                         resource,
                         has_star,
                         id,
                         ...rest
                     }) => {
    const intl = useIntl();
    const {hasPermission} = useAuth();
    const toast = useToast();
    const axiosInstance = useAxios();
    const titleRef = useRef(null);
    const textRef = useRef(null);
    const [isStarred, setIsStarred] = useState(has_star?.includes('RAQIB6'));

    useEffect(() => {
        setTextDirection(titleRef.current, title, 'justify');
        setTextDirection(textRef.current, description.replace(/<[^>]*>?/gm, ''));
    }, [title, description]);

    const toggleStar = async () => {
        try {
            await axiosInstance.patch(`/raqib6/${id}`);
            if (!isStarred) {
                setIsStarred(true);
                toast({
                    title: intl.formatMessage({id:"raqib6.postMarked"}),
                    status: "success",
                    isClosable: true,
                    diration: 1500,
                });
            } else {
                setIsStarred(false);

                toast({
                    title: intl.formatMessage({id:"raqib6.postUnmarked"}),
                    status: "success",
                    isClosable: true,
                    diration: 1500,
                });
            }
        } catch (err) {
            let msg = err?.response?.data?.detail || [];
            if (msg && !Array.isArray(msg)) {
                msg = [{msg: msg}]
            }
            if (!msg.length) {
                msg = [{
                    msg:
                        intl.formatMessage({
                            id: 'responses.unknownError',
                            defaultMessage: 'Something went wrong. Please try again'
                        })
                }]
            }
            msg.map(err =>
                toast({
                    title: err.msg,
                    status: "error",
                    isClosable: true,
                    diration: 1500,
                })
            );
        }
    }

    const thePlatform = configs.raqib6.social_platforms.find(item => item.value === source);

    return (<Card ref={refr} mb={3}>
            <Box textAlign="center" style={{backgroundColor: `rgb(${configs.raqib6.colors[source]})`}}
                 color={'white'}
                 borderTopRightRadius='lg'
                 borderTopLeftRadius='lg'
            >
                <Text>
                    <Link href={resource.origin_url || link} isExternal>
                        [{resource.title}] - {intl.formatMessage({
                        id: "raqib6.on",
                        defaultMessage: "On"
                    })} {intl.formatMessage({
                        id: `raqib6.${thePlatform.value}`,
                        defaultMessage: thePlatform.label
                    })}
                    </Link>
                </Text>
            </Box>
            <CardBody textAlign={'left'}>

                {image && <Image
                    w={'100%'}
                    src={image}
                    alt={title}
                    borderBottomRightRadius='lg'
                    borderBottomLeftRadius='lg'
                />}
                <Stack mt='6' spacing='3'>
                    <Heading size='md' fontFamily={"Cairo"} ref={titleRef}>{title}</Heading>
                    <Box ref={textRef}
                        // noOfLines={7}
                         className={"accented-scrollbar"} overflowY={"scroll"}
                         fontFamily={"Cairo, sans-serif"} whiteSpace={'pre-line'}>
                        <HTMLParser htmlContent={description}/>
                    </Box>
                </Stack>
            </CardBody>
            <Divider/>
            <Flex>
                <Box p='2'>
                    {hasPermission('raqib6', 'fe_star') && !isStarred &&
                        <Tooltip
                            label={intl.formatMessage({id: 'raqib6.mark', defaultMessage: "Mark"})}><span><FaRegStar
                            style={{color: 'orange', display: 'inline-block', marginBottom: '-4px'}}
                            cursor={'pointer'}
                            onClick={toggleStar}/></span></Tooltip>}
                    {hasPermission('raqib6', 'fe_star') && isStarred && <Tooltip
                        label={intl.formatMessage({id: 'raqib6.viewInSource', defaultMessage: "Unmark"})}><span><FaStar
                        style={{color: 'orange', display: 'inline-block', marginBottom: '-4px'}}
                        cursor={'pointer'}
                        onClick={toggleStar}/></span></Tooltip>}
                    <ButtonGroup spacing='2'>
                        <Tooltip
                            label={intl.formatMessage({id: 'raqib6.viewInSource', defaultMessage: "View in source"})}>
                            <Link href={link} isExternal>
                                <ExternalLinkIcon color={`rgb(${configs.raqib6.colors[source]})`} mx="2px"/>
                            </Link>
                        </Tooltip>
                    </ButtonGroup>
                    <Tooltip label={intl.formatMessage({
                        id: 'raqib6.showInGoogleTranslate',
                        defaultMessage: "Show in google translate"
                    })}>
                        <Link ms={1}
                            href={'https://translate.google.com/#view=home&op=translate&sl=auto&tl=fa&text=' + encodeURIComponent(description.replace(/<[^>]*>?/gm, ''))}
                            isExternal><FaGoogle style={{width: '13px', display: 'inline-block', marginBottom: '-4px'}}
                                                 color="blue"/></Link>
                    </Tooltip>
                    <Tooltip
                        label={intl.formatMessage({id: 'raqib6.shareOnTelegram', defaultMessage: "Share on telegram"})}>
                        <Link ms={1}
                              href={`https://t.me/share/url?url=${link}&text=` + encodeURIComponent(description.replace(/<[^>]*>?/gm, ''))}
                              isExternal><ArrowForwardIcon color="cyan.400"/></Link>
                    </Tooltip>

                    {source !== 'NEWS' && hasPermission('post', 'fe_edit') && <Tooltip
                        label={intl.formatMessage({id: 'raqib6.editPost'})}>
                        <Link ms={1} href={`/posts/${id}`} isExternal>
                            <FaPencilAlt style={{width: '13px', display: 'inline-block', marginBottom: '-4px'}}
                                         color="olive"/>
                        </Link>
                    </Tooltip>}

                    {source === 'NEWS' && hasPermission('news', 'fe_edit') && <Tooltip
                        label={intl.formatMessage({id: 'raqib6.editArticle'})}>
                        <Link ms={1} href={`news_articles/${id}`}>
                            <FaPencilAlt style={{width: '13px', display: 'inline-block', marginBottom: '-4px'}}
                                         color="olive"/>
                        </Link>
                    </Tooltip>}

                </Box>
                <Spacer/>
                <Box p='2'>
                    <Tooltip label={gregorianJalaliDTConversion(published_at)} aria-label='datetime-conversion'>
                        <Text color='gray.500' fontSize='sm'>{dtToLocaleString(published_at)}</Text>
                    </Tooltip>
                </Box>
            </Flex>
        </Card>
    );
}